import React, { useState } from "react";

function Input({ ID, type, hint, handleInput }) {
  function handleChange(event) {
    const { value } = event.target;
    handleInput(ID, value);
  }

  return (
    <input
      className="border-none border-b border-gray-300 w-full mb-4 text-lg p-2 outline-none px-3 py-2"
      autoComplete="off"
      type={type}
      placeholder={hint}
      id={ID}
      onChange={handleChange}
    />
  );
}

function Form() {
  const [inputs, setInputs] = useState({
    name: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleInput = (field, value) => {
    setInputs((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputs.name && inputs.message) {
      setSubmitted(true);
      setTimeout(() => setSubmitted(false), 3000); // Hide notification after 3 seconds
    } else {
      alert("Please fill in all fields.");
    }
  };

  return (
    <div className="m-0 p-0">
      <form className="ml-4 h-full w-full" onSubmit={handleSubmit}>
        <h1 className="text-4xl text-whi">Contact Us</h1>
        <br />
        <Input
          ID="name"
          type="text"
          hint="Enter your name"
          handleInput={handleInput}
        />
        <div className="flex justify-center items-center">  
        <textarea
          className="border-none border-b border-gray-300 w-full mt-6 mb-4 text-xl resize-none outline-none px-3 py-2"
          id="message" 
          rows="5"
          placeholder="Questions/comments..."
          onChange={(e) => handleInput("message", e.target.value)}
        ></textarea>
        <button
          type="submit"
          className="h-12 w-36 text-lg rounded-full bg-gradient-to-r from-red-300 to-red-500 text-white mt-5 ml-28"
        >
          Submit
        </button>
        </div>
       
        {submitted && (
          <div className="text-red-500 text-xl mt-4 w-full text-center">
            Your message has been sent!
          </div>
        )}
      </form>
    </div>
  );
}

export default Form;
