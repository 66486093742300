import React from "react";
import Cards from "../Components/Cards";
import Banner from "../Components/Banner";
import About from "../Components/About";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import Contact from '../Pages/Contact'
export default function Home() {
  const [cookies, setCookies] = useCookies("cookieConsent");

  const giveCookieConsent = () => {
    setCookies("cookieConsent", true, { path: "/" });
  };
  return (
    <>
      <Banner />
      <Cards />
      <About />
<Contact/>
      <div>
        cookies <Link to="/terms"></Link>
        <button onClick={giveCookieConsent}>accept</button>
      </div>
    </>
  );
}
