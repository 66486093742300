import { FaStar } from 'react-icons/fa'
import logo1 from '../assets/logo/logo1.webp'
import logo2 from '../assets/logo/logo2.webp'
import logo3 from '../assets/logo/logo3.webp'
import logo4 from '../assets/logo/logo4.webp'
import logo5 from '../assets/logo/logo5.webp'
import logo6 from '../assets/logo/logo6.webp'
import logo9 from '../assets/logo/logo9.webp'
import logo7 from '../assets/logo/logo7.webp'
import logo8 from '../assets/logo/logo8.webp'
import logo10 from '../assets/logo/room1.jpeg'
import top1 from '../assets/images/top2.webp'
import top3 from '../assets/images/top3.webp'
import top4 from '../assets/images/top4.webp'
import top5 from '../assets/images/top5.webp'
import top2 from '../assets/images/top6.webp'

export const data = [
  // //1
  // {
  //   id: 1,
  //   logo: logo1,
  //   stars: [
  //     <FaStar className='text-yellow-500 mr-1 text-xl' />,
  //     <FaStar className='text-yellow-500 mr-1 text-xl' />,
  //     <FaStar className='text-yellow-500 mr-1 text-xl' />,
  //     <FaStar className='text-yellow-500 mr-1 text-xl' />,
  //     <FaStar className='text-yellow-500 mr-1 text-xl' />,
  //   ],
  //   address: '8/9 Talbot Street, Dublin 1, Dublin, Ireland',
  //   about: [
  //     <li key={1}>Phone: 01 874 8333</li>,
  //     <li key={2}>Email: info@playlandcasino.ie</li>,
  //   ],
  //   score: '9.2',
  //   link: 'https://playlandcasino.ie/',
  // },
  //2
  {
    id: 2,
    logo: logo2,
    stars: [
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
    ],
    address: '111 Rathmines Road Lower, Dublin 6, Dublin, Ireland',
    about: [
      <li key={1}>Phone: 01 496 9135</li>,
      <li key={2}>Email: contact@cqleisure.ie</li>,
    ],
    score: '8.9',
    link: 'https://goldrushcasino.ie/?utm_source=HeyDublin',
  },
  // 3
  {
    id: 3,
    logo: logo3,
    stars: [
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
    ],
    address: 'The Square Shopping Centre, Tallaght, Dublin 24, Dublin, Ireland',
    about: [
      <li key={1}>Phone: 01 862 2601</li>,
      <li key={2}>Email: info@expocasino.ie</li>,
    ],
    score: '8.9',
    link: 'https://expo.casino/?utm_source=HeyDublin',
  },
  //4
  {
    id: 4,
    logo: logo4,
    stars: [
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
    ],
    address: '153 Parnell street, Rotunda, Dublin 1, Dublin, Ireland',
    about: [
      <li key={1}>Phone: 01 872 7342</li>,
      <li key={2}>Email: info@morris21casinos.com</li>,
    ],
    score: '7.6',
    link: 'https://www.morris21casinos.com/?utm_source=HeyDublin',
  },
  //5
  // {
  //   id: 5,
  //   logo: logo5,
  //   stars: [
  //     <FaStar className='text-yellow-500 mr-1 text-xl' />,
  //     <FaStar className='text-yellow-500 mr-1 text-xl' />,
  //     <FaStar className='text-yellow-500 mr-1 text-xl' />,
  //     <FaStar className='text-yellow-500 mr-1 text-xl' />,
  //   ],
  //   address: '4 Westmoreland street, Dublin 2, Dublin, Ireland',
  //   about: [
  //     <li key={2}>Phone: 01 474 3273</li>,
  //     <li key={1}>Email :info@amusementcity.com</li>,
  //   ],
  //   score: '8.0',
  //   link: 'https://www.amusementcity.ie/?utm_source=HeyDublin',
  // },
  //6
  {
    id: 6,
    logo: logo6,
    stars: [
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
    ],
    address:
      'South Anne Street, Dublin 2, Dublin, Ireland',
    about: [
      <li key={2}>Phone: 01 703 0600</li>,
      <li key={1}>Email: info@thesportingemporium.com</li>,
    ],
    score: '7.5',
    link: 'https://www.thesportingemporium.com/?utm_source=HeyDublin',
  },

  //7
  {
    id: 9,
    logo: logo9,
    stars: [
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
    ],
    address: "55/56 O'Connell Street Dublin 1, Dublin, Ireland",
    about: [
      <li key={2}>Phone: 01 515 4704</li>,
      <li key={1}>Email: bookings@carltoncasinoclub.com</li>,
    ],
    score: '6.8',
    link: 'https://carltoncasinoclub.gravity.ie/',
  },
  //8
  {
    id: 7,
    logo: logo10,
    stars: [
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
    ],
    address: '63 Lower Dorset Street,  Dublin 1, Dublin, Ireland',
    about: [
      <li key={2}>Phone: 01 830 6764</li>,
      <li key={1}>Email: info@gamcare.org.uk</li>,
    ],
    score: '6.8',
    link: 'https://d1casino.ie/',
  },
  {
    id: 9,
    logo: logo7,
    stars: [
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
    ],
    address: 'Ted Nealon Rd, Adelaide Street, Abbeyquarter North, Sligo, Ireland',
    about: [
      <li key={2}>Phone: 071 915 0515</li>,
      <li key={1}>Email: contact@adelaidecasino.ie</li>,
    ],
    score: '7.5',
    link: 'http://www.adelaidecasino.ie/',
  },
  {
    id: 10,
    logo: logo8,
    stars: [
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
      <FaStar className='text-yellow-500 mr-1 text-xl' />,
    ],
    address: 'The Westbury Club, An Faiche, Malahide, Co. Dublin, Ireland',
    about: [
      <li key={2}>Phone:01 845 3488</li>,
      <li key={1}>Email: info@westburyclub.com</li>,
    ],
    score: '7.9',
    link: 'https://www.westburyclub.com/index.php',
  },
]

export const dataTop = [
  //1
  {
    id: 1,
    image: top1,
    description:
      'North-west of Galway city lies one of Ireland’s most alluring wildernesses. The haunting beauty of the Connemara region stretches across County Galway: a landscape of slate-coloured lakes, bogland, sheep-dotted mountains, rugged coastline, hidden bays and small towns. Stop at Killary Harbour, Ireland’s only fjord; the scenically situated Kylemore Abbey; the Alcock and Brown monument near Clifden that commemorates the landing site of the first non-stop transatlantic flight in 1919; and take to the hiking trails of the 40,000-acre Connemara National Park.',
  },
  //2
  {
    id: 2,
    image: top2,
    description:
      'In the 19th and 20th centuries, Ireland was criss-crossed with a much larger network of regional railways than it is today. Some of the now-disused lines are being imaginatively repurposed into off-road walking and cycling routes. As its name hints, the Waterford Greenway is a 28-mile trail between the southerly port city of Waterford and seaside town Dungarvan, passing through tranquil countryside over viaducts and through moss-strewn railway tunnels with flashes of sea views.',
  },
  //3
  {
    id: 3,
    image: top3,
    description:
      'The rejuvenating properties of seaweed should not be underestimated. In the early 20th century, there were more than 300 seaweed baths dotted around the coast of Ireland, but numbers dwindled in the ensuing decades and only a handful remain. Voya Seaweed Baths in Strandhill is just one of a number rediscovering this very ancient therapy for a whole new audience, hand-harvesting organic seaweed from the nearby beaches and combining it with mineral-rich seawater for the ultimate steamy soak.',
  },
  //4
  {
    id: 4,
    image: top4,
    description:
      'The scenic photo opportunities just keep coming on the 112-mile Ring of Kerry, one of Ireland’s most celebrated touring routes. Roughly skirting the edges of the Iveragh Peninsula in the south-western corner of the country, it starts and ends in Killarney. Along the way it threads through a ravishing reveal of mountains and Atlantic-bashed coastal views that include the UNESCO World Heritage-listed monastic settlement on Skellig Michael and the glorious golden sands of Rossbeigh Beach.',
  },
  //5
  {
    id: 5,
    image: top5,
    description:
      'Dingle feels a long way from anywhere, but the charm of this west Kerry fishing town in the heart of the Gaelic-speaking region is worth the trip. The pubs lining Main Street double as grocery stores and its annual gatherings such as the Other Voices music festival and the Dingle Food Festival draw a crowd. It’s also the jumping-off point for the remote charms of its namesake peninsula: the vertiginous drive around Slea Head with its views of the Blasket Islands; Sybil Head, which was used as a filming location in in Star Wars: The Last Jedi; and, in good weather, the Caribbean-like the water lapping Coumeenoole Strand (aka Ryan’s Daughter beach), where David Lean’s classic 1970 flick was shot.',
  },
]
