import Routes from "./Routes";
import { useEffect } from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";


// function loadGTM() {
//   const script = document.createElement("script");
//   script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-WT55PC3W";
//   script.async = true;
//   document.head.appendChild(script);
// }


function App() {
  // useEffect(() => {
  //   loadGTM();
  // }, []);
  return (
    <div className="bg-[#00080E]">
      <div className="w-[85%] m-auto">
        <Navbar />
        <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="mySiteCookieConsent"
        style={{ background: "transparent", border:"1px solid black" }}
        buttonStyle={{ color: "white", fontSize: "14px" }}
        expires={150}
        onAccept={() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'consent_given'
          });
         
        }}
      >
      This site uses cookies. Please accept cookies for a better user experience.
      </CookieConsent>
        <Routes />
        <Footer />
      </div>
    </div>
  );
}

export default App;
