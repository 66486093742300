import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='text-white m-auto w-[80%]'> 
      <h1 className='text-3xl mb-6 font-bold'> Privacy Policy</h1>
      <p>Welcome to <a href="https://luckyrollshub.com">www.luckyrollshub.com</a>. Protecting your privacy is a priority for us. This document outlines the types of information we collect and how we use it at www.luckyrollshub.com.</p>
      
      <div className='mb-3'> 
        <h2 className='text-2xl mb-3  font-semibold'>Contact Us</h2>
        <p>If you require more information or have questions about our privacy policy, please do not hesitate to contact us.</p>
      </div>

      <div className='mb-3'> 
        <h2 className='text-2xl mb-3  font-semibold'>Log Files</h2>
        <p>www.luckyrollshub.com utilizes log files following standard procedures. These files log visitors when they access websites, which is a part of hosting services' analytics. The information collected includes IP addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. This information is not linked to any personally identifiable information but is used for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
      </div>

      <div className='mb-3'> 
        <h2 className='text-2xl mb-3  font-semibold'>Cookies and Web Beacons</h2>
        <p>Like many other websites, www.luckyrollshub.com uses 'cookies'. These cookies store information such as visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the user experience by customizing our web page content based on visitors' browser type and other information.</p>
      </div>

      <div className='mb-3'> 
        <h2 className='text-2xl mb-3  font-semibold'>Third-Party Privacy Policies</h2>
        <p>Our privacy policy does not apply to other advertisers or websites. It is advisable to consult the respective privacy policies of these third-party ad servers for more detailed information on their practices as well as for instructions on how to opt-out of certain practices.</p>
      </div>

      <div className='mb-3'> 
        <h2 className='text-2xl mb-3  font-semibold'>Children's Information</h2>
        <p>We believe it is important to provide added protection for children online. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. www.luckyrollshub.com does not knowingly collect any personally identifiable information from children under the age of 13. If you think that your child provided this kind of information on our website, please contact us immediately and we will do our utmost to promptly remove such information from our records.</p>
      </div>

      <div className='mb-3'> 
        <h2 className='text-2xl mb-3  font-semibold'>Online Privacy Policy Only</h2>
        <p>This privacy policy applies only to our online activities and is valid for visitors to our website with regards to the information they shared and/or collect in www.luckyrollshub.com. This policy does not apply to any information collected offline or via channels other than this website.</p>
      </div>

      <div className='mb-3'> 
        <h2 className='text-2xl mb-3  font-semibold'>Consent</h2>
        <p>By using our website, you hereby consent to our privacy policy and agree to its terms.</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
