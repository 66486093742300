import { Route, Routes } from 'react-router-dom'
import React from 'react'
import Home from './Pages/Home'
import About from './Pages/About'
import IrelandTop from './Pages/IrelandTop'
import Terms from './Pages/Terms'
import Privacy from './Pages/Privacy'
import { Cookies } from 'react-cookie'
import Contact from './Pages/Contact'

export default function MyRoutes({ user }) {

  return (
    <Routes>
      <Route path='/' element={<Home user={user} />} />
      <Route path='/top' element={<IrelandTop />} />
      <Route path='/about' element={<About />} />
      <Route path='/terms' element={<Terms />} />
      <Route path='/privacyPolicy' element={<Privacy />} />
    </Routes>
  )
}
