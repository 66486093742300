import { dataTop } from '../helpers/data'


export default function IrelandTop() {
  return (
    <div className='p-3 sm:p-8 my-4 flex flex-col sm:flex-row items-center justify-between rounded-2xl bg-black shadow-md shadow-slate-200/50 border-top border-[1px] border-zinc-400/45 min-h-[100vh]'>
      <div className='text-white text-center sm:text-left'>
      <h2 className='text-base sm:text-2xl text-center sm:text-left'>Ireland's top destinations</h2>
        <ul className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          {dataTop.map((top, index) => (
            <div
              key={top.id}
              className='flex flex-col sm:flex-row justify-between items-center text-white bg-[#0E191F] px-3 sm:px-10 py-7 my-3 rounded-xl transition duration-300 ease-in-out transform hover:shadow-lg hover:shadow-slate-200/50 hover:brightness-95'
            >
              <div className='w-full mb-5 sm:m-0 sm:w-1/2'>
              <p className='text-xs px-2 sm:text-sm'>{top.description}</p>

              </div>
             
              <div className='w-full sm:w-1/2 '>
                <img
                  src={top.image}
                  alt='images'
                  className='w-[500px] h-[120px] sm:h-[200px] object-cover m-auto'
                />
              </div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  )
}
