import React from 'react'
import banner from '../assets/banner1.webp'
import { LazyLoadImage } from 'react-lazy-load-image-component';


export default function Banner() {
  return (
    <div className='p-3 sm:p-8 mb-14 flex flex-col sm:flex-row items-center justify-between rounded-2xl bg-black shadow-md shadow-slate-200/50 border-top border-[1px] border-zinc-400/45'>
      <div className='w-[65%] my-7 sm:my-0 text-white text-center sm:text-left absolute'>
        <h2 className='text-xl sm:text-4xl font-bold '>
          Discover Exceptional  Casino Experiences in Ireland
        </h2>
        <p className='text-sm sm:text-base font-normal my-4'>
          Embark on a journey through Ireland's finest  casino destinations.
          At  luckyrollshub , we believe that adventure comes in many forms,
          from exquisite dining experiences to luxurious accommodations in
          stunning natural settings. Join us in exploring the remarkable world
          of hospitality, where every stay is an opportunity for unforgettable
          memories. Begin your Irish escape today!
        </p>
      </div>
      <div className='w-full  h-[400px]  flex justify-end'>
        <LazyLoadImage src={banner} alt='banner'  className='h-[400px]nw-full sm:w-[70%] object-cover' />
      </div>
    </div>
  )
}
