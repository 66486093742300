import React from 'react'

export default function About() {
  return (
    <div className='p-3 sm:p-8 my-14 flex flex-col sm:flex-row items-center justify-between rounded-2xl bg-black shadow-md shadow-slate-200/50 border-top border-[1px] border-zinc-400/45 '>
      <ul className='text-white text-center sm:text-left'>
        <li>
          We're dedicated to celebrating the vast tapestry of experiences that
          Ireland has to offer. Our mission is to inspire and guide travelers,
          adventurers, and seekers of remarkable moments as they navigate the
          breadth and depth of this incredible nation. For a relatively small
          country, Ireland scores high in the sightseeing stakes from the
          elegant Georgian streets of its capital Dublin, to the more elemental
          and remote appeal of its further flung beauty spots and historic
          landmarks. The craic might be mighty and the welcome warm, but it’s
          not just a nostalgia-steeped vision of the past that is the draw,
          modern-day Ireland is a progressive, youthful and cosmopolitan society
          with a fast-moving contemporary scene when it comes to art, film,
          music, food, literature and tech with something new to discover all
          the time.
        </li>
      </ul>
    </div>
  )
}
