import React from 'react'
import { data } from '../helpers/data'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Cards() {
  return (
    <>
      {data.map((card, idx) => (
        <a href={card.link}>
      <div
      key={idx}
      className='flex flex-col sm:flex-row justify-between items-center text-white bg-[#0E191F] px-3 sm:px-10 py-7 sm:py-9  my-3 rounded-xl transition duration-300 ease-in-out transform hover:shadow-lg  hover:shadow-slate-200/50 hover:brightness-95'
    >
          {/* logo and address */}
          <div className='flex flex-col sm:flex-row items-center w-full sm:w-3/4 gap-4'>
            <div className='w-full sm:w-48 h-24 flex flex-col justify-center items-center'>
              <LazyLoadImage
                src={card.logo}
                effect="blur"
                alt='logo'
                className='rounded-lg w-[50%] sm:w-full h-auto  object-cover m-auto '
              />
              <div className='mt-2 flex justify-center'>
                {card.stars.map((star, index) => (
                  <span key={index}>{star}</span>
                ))}
              </div>
            </div>
            <div className='w-full sm:w-1/3 '>
              <h3 className='text-sm sm:text-base font-semibold text-center sm:text-left capitalize'>{card.address}</h3>
            </div>
            <div className='w-1/3 hidden text-xs md:text-sm sm:block '>
              {card.about.map((star, index) => (
                <li key={index} className='list-none'>
                  {star}
                </li>
              ))}
            </div>
          </div>

          <div className='flex flex-col w-full sm:w-1/4 items-center'>
            <h4 className='text-2xl sm:text-5xl font-semibold my-4'>{card.score}</h4>
            <button
              className='bg-gradient-to-r from-red-300 to-red-500 px-16 py-4 sm:py-3 w-full sm:w-auto rounded-3xl text-white hover:filter hover:brightness-110 transition duration-300 font-semibold sm:font-normal '
              style={{
                filter: 'none',
                transition: 'filter 0.3s ease-in-out',
              }}
              onMouseOver={(event) => {
                event.target.style.filter =
                  'drop-shadow(0 0 10px rgba(255, 213, 79, 0.8))'
              }}
              onMouseOut={(event) => {
                event.target.style.filter = 'none'
              }}
            >
              VISIT SITE
            </button>
          </div>
        </div>
        </a>
      ))}
    </>
  )
}
