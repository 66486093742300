// Navbar.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

const Navbar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <nav className="py-7">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <a href="/">
          <img src={logo} alt="logo" className="w-[100px] h-[100px]" />
        </a>

        {/* Navbar Items (hidden on small screens) */}
        <div className="hidden md:flex space-x-4 gap-4">
          <Link to="/" className="text-white hover:text-red-400">
            Home
          </Link>
          <Link to="/top" className="text-white hover:text-red-400">
            Ireland's Top Destinations
          </Link>
          <Link to="/about" className="text-white hover:text-red-400">
            About
          </Link>
          <Link to="/terms" className="text-white hover:text-red-400">
            Terms & Conditions
          </Link>

          <Link to="/privacyPolicy" className="text-white hover:text-red-400">
            Privacy Policy
          </Link>
        </div>

        {/* Sidebar Icon (visible on small screens) */}
        <div className="md:hidden">
          <button
            className="text-white focus:outline-none text-2xl"
            onClick={toggleSidebar}
          >
            ☰
          </button>
        </div>
      </div>

      {/* Sidebar (visible on small screens) */}
      {isSidebarOpen && (
        <div className="md:hidden p-4">
          <Link to="/" className="block text-white mb-2 hover:text-red-400">
            Home
          </Link>
          <Link to="/top" className="block text-white mb-2 hover:text-red-400">
            Ireland's Top Destinations
          </Link>
          <Link
            to="/about"
            className="block text-white mb-2 hover:text-red-400"
          >
            About
          </Link>
          <Link
            to="/terms"
            className="block text-white mb-2 hover:text-red-400"
          >
            Terms & Conditions
          </Link>

          <Link
            to="/privacyPolicy"
            className="block text-white mb-2 hover:text-red-400"
          >
            Privacy Policy
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
