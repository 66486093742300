import React from 'react'
import logo from '../assets/logo.png'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='flex flex-col sm:flex-row w-full justify-between items-center p-4'>
      <div className='w-40 h-24'>
        <a href='/' className='w-full m-auto'>
          <img src={logo} alt='logo' className='w-[100px] h-[100px]  m-auto' />
        </a>
      </div>
      <div className='flex   text-white'>
        <ul className='flex flex-col sm:flex-row   text-center sm:text-left'>
          <li className='mx-2  my-1 sm:my-0 hover:underline'>
            <Link to='/'> Home</Link>
          </li>
          <li className='mx-2  my-1 sm:my-0 hover:underline'>
            <Link to='/top'> Ireland's Top Destinations</Link>
          </li>
          <li className='mx-2  my-1 sm:my-0 hover:underline'>
            <Link to='/about'>About</Link>{' '}
          </li>
          <li className='mx-2  my-1 sm:my-0 hover:underline'>
            <Link to='/terms'> Terms & Conditions</Link>
          </li>
          <li className='mx-2  my-1 sm:my-0 hover:underline'>
            <Link to='/privacyPolicy'> Privacy Policy</Link>
          </li>
         
        </ul>
      </div>
    </div>
  )
}
