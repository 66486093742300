import React from 'react'

export default function About() {

  return (
    <div className='p-3 sm:p-8 my-4 flex flex-col sm:flex-row items-center justify-between rounded-2xl bg-black shadow-md shadow-slate-200/50 border-top border-[1px] border-zinc-400/45 min-h-[100vh]'>
      <div className='text-white text-center sm:text-left'>
        <p className="text-sm sm:text-base font-base sm:font-semibold my-3">
          Welcome to luckyrollshub, your trusted source for discovering
          the finest   casinos in Ireland. We understand that a memorable
          getaway isn't just about games; it's about unforgettable experiences,
          world-class accommodations, and impeccable service. That's why we're
          here to guide you through the top-rated   casinos across the
          beautiful landscapes of Ireland.{' '}
        </p>

        <p className="text-sm sm:text-base font-base sm:font-semibold my-3">
          At the website, we're passionate about helping you plan the perfect
          retreat where entertainment and luxury converge. Our mission is to
          provide you with comprehensive information and unbiased ratings of
          Ireland's leading   casinos, allowing you to make informed
          decisions for your next adventure.
        </p>
        <h2 className="text-xl my-1 sm:my-8 sm:text-3xl  font-normal sm:font-bold">What Sets Us Apart</h2>
        <p className="text-sm sm:text-base font-base sm:font-semibold my-3">
          Unbiased Reviews: We pride ourselves on offering objective and honest
          evaluations of the   casinos featured on our platform. Our team of
          experienced reviewers visits each establishment to assess their
          amenities, service quality, and overall guest experience, ensuring
          that you have all the information you need to make the right choice.
        </p>
      
        <p className="text-sm sm:text-base font-base sm:font-semibold my-3">
          Detailed Ratings: Our rating system takes into account factors such as
          room quality, dining options, recreational activities, and the overall
          ambiance of each   casino. We understand that a great casino
          experience involves more than just gaming, and our ratings reflect
          that perspective.
        </p>
        <h2 className="text-xl my-1 sm:my-8 sm:text-3xl  font-normal sm:font-bold">How We Can Help You </h2>
        <p className="text-sm sm:text-base font-base sm:font-semibold my-3">
          Whether you're planning a romantic getaway, a family vacation, or a
          solo adventure, luckyrollshub is your go-to resource for finding
          the perfect   casino in Ireland. Our website simplifies the process
          of comparing and choosing the ideal destination based on your
          preferences and priorities.
        </p>
        <p className="text-sm sm:text-base font-base sm:font-semibold my-3">
          Feel free to explore our listings, read our in-depth reviews, and use
          our ratings to make well-informed decisions. We're here to make your
          journey as seamless and enjoyable as possible.
        </p>
        <p className="text-sm sm:text-base font-base sm:font-semibold my-3">
          Thank you for choosing luckyrollshub as your trusted partner in
          exploring Ireland's top-rated   casinos. We look forward to being a
          part of your travel journey and helping you create lasting memories in
          this beautiful country.
        </p>
        <p className="text-sm sm:text-base font-base sm:font-semibold my-3">
          Start your adventure today and discover the best   casinos that
          Ireland has to offer!
        </p>
      </div>
    </div>
  )
}
