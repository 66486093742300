import React from 'react'

export default function Terms() {
  return (
    <div className='p-3 sm:p-8 my-4 flex flex-col sm:flex-row items-center justify-between rounded-2xl bg-black shadow-md shadow-slate-200/50 border-top border-[1px] border-zinc-400/45'>
      <div className='text-white text-center sm:text-left'>
        <h2 className='text-xl my-1 sm:my-8 sm:text-3xl  font-normal sm:font-bold'>
          GENERAL TERMS OF USE
        </h2>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          These General Terms and Conditions ("GTC") establish a legally binding
          agreement between you and us regarding the use of our services and
          website ("Website" or "Services"). It is imperative that you
          thoroughly comprehend these terms and conditions. By accessing,
          connecting to, or utilizing our Website, you confirm your
          understanding and acceptance of these terms and conditions, including
          the privacy policy, and your commitment to comply with all applicable
          laws and regulations. You must be at least 18 years old to access this
          Website.
        </p>

        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          If you disagree with these terms and conditions, please refrain from
          visiting or using the Website or its Services in any manner.
        </p>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          Please take note of the following crucial points: the Website, its
          content, and Services are provided "as is." We shall not be held
          liable for any harm or loss incurred by you or any other party
          resulting from your use of our Website or Services. Your use of the
          Website and Services is at your own risk.
        </p>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          All rights to the content of the Website and Services are reserved. We
          do not assume any explicit or implied responsibility for actions,
          decisions, or lack thereof, made based on the content of this Website.
          We disclaim any liability for any losses, injuries, or damages of any
          nature arising from or related to such actions or decisions.
        </p>
        <h2 className='text-xl my-1 sm:my-8 sm:text-3xl  font-normal sm:font-bold'>
          USE OF THE WEBSITE AND SERVICES
        </h2>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          You may only use the Website and its materials for personal,
          non-commercial purposes.
        </p>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          You may only use the Website and its materials for personal,
          non-commercial purposes.
        </p>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          You commit to using the Website in compliance with these terms and
          conditions, along with all pertinent laws and regulations. Any
          comments, messages, or postings you make on this Website ("User
          Generated Content") are your responsibility. You are accountable for
          any activities that contravene these terms and conditions, and you
          bear responsibility for any damages resulting from such violations.
          The use of the Website in a manner that disrupts others' use is
          strictly prohibited. We reserve the right to investigate and report
          any breaches or violations of these terms and conditions.
        </p>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          Upon detecting any violations of these terms and conditions, we may
          restrict your access to the Website. Additionally, we retain the
          discretion to limit your use of the Website for any reason and to
          terminate your access at our sole discretion. We reserve the right to
          decline acceptance, publication, or transmission of any content you
          submit to the Website.
        </p>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          Please be aware that we are not responsible for storing or monitoring
          any content provided by you or posted on the Website, including
          user-generated content.
        </p>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          Please review the terms and conditions and the data protection
          statement on the game/casino operator's website to ensure that you are
          permitted to use the services and meet all legal requirements (e.g.,
          certain countries may prohibit residents from using the services of
          such operators).
        </p>

        <h2 className='text-xl my-1 sm:my-8 sm:text-3xl  font-normal sm:font-bold'>
          THIRD PARTY LINKS
        </h2>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          The Website contains links to third-party websites, along with reviews
          and comments about these third-party websites. Such links may be
          published by us, you, other users, Google AdSense, or other parties.
          Third-party websites linked from our Website are beyond our control.
          We are not liable for any loss or damage resulting from accessing a
          third-party website, even if it is linked to us. In some cases, we may
          receive commissions from affiliate programs run by third-party
          websites. Our participation in any such affiliate programs does not
          constitute an endorsement of the respective website.
        </p>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          Feel free to explore our listings, read our in-depth reviews, and use
          our ratings to make well-informed decisions. We're here to make your
          journey as seamless and enjoyable as possible.
        </p>
        <h2 className='text-xl my-1 sm:my-8 sm:text-3xl  font-normal sm:font-bold'>
          HOW WE RATE
        </h2>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          The rankings and ratings displayed on the Website, including the
          aforementioned content, are based on a combination of user experience
          ratings, views, ratings, comments, and our proprietary ranking and
          rating algorithms. In some cases, we may earn advertising revenue from
          the service providers featured on the Website, including those
          featured in our rankings and comparisons, to provide you with this
          information at no cost. We explicitly disclaim any warranties or
          guarantees regarding the marketability, suitability, completeness,
          applicability, or accuracy of the information. We make diligent
          efforts to ensure the content is up-to-date and accurate.
        </p>

        <h2 className='text-xl my-1 sm:my-8 sm:text-3xl  font-normal sm:font-bold'>
          WARRANTY AND LIABILITY DISCLAIMER
        </h2>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          We expressly disclaim all warranties, both explicit and implied,
          regarding the Website, including, but not limited to, implied
          warranties and conditions of merchantability, fitness for a particular
          purpose, and non-infringement. The Website, content, and reviews are
          provided "as is," and your use thereof is at your own risk. Any damage
          to your computer systems resulting from Website usage is solely your
          responsibility. We do not guarantee continuous availability of the
          Website. We do not guarantee the accuracy, reliability, or
          truthfulness of information and content on this Website. We do not
          guarantee the correction of errors, defects, or omissions on the
          Website.
        </p>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          We cannot guarantee the factual accuracy of third-party website
          reviews. Our assessments of third-party websites are based on our
          personal experiences, information from other sources, and, in some
          cases, our own research. We make no assurances regarding your
          satisfaction with third-party websites or the services you anticipate
          from them.
        </p>

        <h2 className='text-xl my-1 sm:my-8 sm:text-3xl  font-normal sm:font-bold'>
          LIMITATION OF LIABILITY
        </h2>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          You explicitly acknowledge and agree that we are not liable for any
          direct, indirect, incidental, special, or consequential damages or
          losses. We also disclaim any responsibility for financial losses, job
          losses, or advice stemming from your use of this Website, the
          information on this Website, or your interactions with our Website,
          services, or affiliates. This limitation of liability applies even if
          we have been advised of the possibility of such damages or losses.
        </p>

        <h2 className='text-xl my-1 sm:my-8 sm:text-3xl  font-normal sm:font-bold'>
          DAMAGES
        </h2>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          You agree to hold us harmless and indemnify us against any and all
          liabilities arising from or related to your use of our Website,
          including claims, losses, damages, and consequential damages, as well
          as claims, judgments, litigation, and legal fees of any kind.
        </p>

        <h2 className='text-xl my-1 sm:my-8 sm:text-3xl  font-normal sm:font-bold'>
          CONTENT GUIDELINES
        </h2>
        <p className='text-sm sm:text-base font-base sm:font-semibold my-3'>
          You acknowledge that the Website is provided solely for your
          entertainment and may contain content that you find objectionable or
          inappropriate. You use the Website at your own risk. We make efforts
          to monitor the Website and the content you submit or view, including
          user-generated content, but we are not responsible for such content.
          You are responsible for reviewing all content you post on the Website.
          We neither approve nor disapprove of any information posted on the
          Website. You are responsible for ensuring that you have the legal
          right, authorization, and authority to publish your content on the
          Website. We reserve the right to review and/or remove any
          user-generated content that contains or relates to: (1) pornography or
          obscene material; (2) pedophilia, incest, or bestiality; (3) threats
          of violence; (4) inflammatory or hateful references to any population
          group, ethnic group, religion, disabled person, gender, age, or sexual
          orientation; (5) infringement of copyrights, trademarks, or property
          rights of others; (6) private or confidential information of others;
          and (7) spam, advertising for other services or products, or malicious
          software (such as viruses or malware).
        </p>
      </div>
    </div>
  )
}
